import classNames from 'classnames';

import './index.style.scss';

export type ShadowBoxProps = {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
};

const ShadowBox = ({ children, className }: { children: React.ReactNode; className?: string }) => {
    const classes = classNames('shadow-box', className);

    return <div className={classes}>{children}</div>;
};
export default ShadowBox;
