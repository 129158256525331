import React from 'react';

import { BadgeIconProps } from './badge.props';

const BadgeIcon = ({ className, style }: BadgeIconProps) => {
    return (
        <div className={className} style={style}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="20" fill="#5CC4ED" />
                <path
                    d="M19.0645 17.1668L17.403 15.1152C16.4263 13.9558 14.6747 13.8242 13.4908 14.8059C13.4824 14.8128 13.4741 14.8198 13.4658 14.8268C12.3112 15.8072 12.155 17.5276 13.1168 18.6694L14.0426 19.8748"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.0417 19.875L9.56806 23.4755C8.88012 24.0469 8.7839 25.0881 9.46221 25.8736C10.1405 26.659 11.2186 26.5474 11.8253 26.0528L13.3004 24.8502C14.8031 26.7475 16.857 27.6667 19.5535 27.6667C23.6076 27.6667 26.9759 24.8913 27.6626 21.2363C27.8333 20.3271 28.2454 19.4745 28.8874 18.7801L30.793 17.4269C31.069 17.222 31.069 16.8261 30.793 16.6211C29.9771 16.0148 29.2219 15.3318 28.5985 14.5466C27.8527 13.6075 26.6668 13 25.331 13C25.2812 13 25.2274 13.0024 25.1708 13.0063C24.5629 13.0506 23.9833 13.2563 23.4844 13.5836C23.4715 13.5916 23.459 13.6002 23.4464 13.6088C23.3629 13.665 23.2817 13.7242 23.2032 13.7871L21.7908 14.9205L19.38 16.8703"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default BadgeIcon;
