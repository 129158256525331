import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

import CardIcon from 'ui-kit/icons/discount-card/card/card';
import GlobeIcon from 'ui-kit/icons/discount-card/globe/globe';
import MagnifyingGlassIcon from 'ui-kit/icons/discount-card/magnifying-glass/magnifying-glass';
import PaymentIcon from 'ui-kit/icons/discount-card/payment/payment';
import PhoneIcon from 'ui-kit/icons/discount-card/phone/phone';
import PrinterIcon from 'ui-kit/icons/discount-card/printer/printer';
import Logo from 'ui-kit/logo/logo';

import Card from '../card';
import './pdf.styles.scss';

export interface DiscountCardProps {
    memberId: string;
    bin: string;
    pcn: string;
    group: string;
    targetRef?: MutableRefObject<undefined>;
}

const PDF = (props: DiscountCardProps) => {
    const { t } = useTranslation();
    return (
        <div className="discount-card-pdf" id="discount-card-pdf" ref={props.targetRef}>
            <div className="discount-card-pdf__header">
                <Logo />
                <h1>{t('pages.discountCard.pdf.title')}</h1>
            </div>
            <div className="discount-card-pdf__card">
                <h2>{t('pages.discountCard.pdf.subTitle')}</h2>
                <div className="discount-card-pdf__card__frame">
                    <Card
                        memberId={props.memberId}
                        bin={props.bin}
                        pcn={props.pcn}
                        group={props.group}
                        useSVG={false}
                    />
                </div>
            </div>
            <div className="discount-card-pdf__about-card">
                <h2>{t('pages.discountCard.pdf.aboutCard.title')}</h2>
                <div className="discount-card-pdf__about-card__list">
                    <div className="discount-card-pdf__about-card__list__description">
                        <PrinterIcon />
                        <div>
                            <h3>{t('pages.discountCard.pdf.aboutCard.descriptions.title1')}</h3>
                            <p>{t('pages.discountCard.pdf.aboutCard.descriptions.description1')}</p>
                        </div>
                    </div>
                    <div className="discount-card-pdf__about-card__list__description">
                        <MagnifyingGlassIcon />
                        <div>
                            <h3>{t('pages.discountCard.pdf.aboutCard.descriptions.title2')}</h3>
                            <p>{t('pages.discountCard.pdf.aboutCard.descriptions.description2')}</p>
                        </div>
                    </div>
                    <div className="discount-card-pdf__about-card__list__description">
                        <CardIcon />
                        <div>
                            <h3>{t('pages.discountCard.pdf.aboutCard.descriptions.title3')}</h3>
                            <p>{t('pages.discountCard.pdf.aboutCard.descriptions.description3')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="discount-card-pdf__sharing">
                <PaymentIcon />
                <div className="discount-card-pdf__sharing__info">
                    <h2>{t('pages.discountCard.pdf.sharing.title')}</h2>
                    <p>{t('pages.discountCard.pdf.sharing.subTitle')}</p>
                    <p className="discount-card-pdf__sharing__info__bold">
                        {t('pages.discountCard.pdf.sharing.subTitle2')}
                    </p>
                </div>
            </div>
            <div className="discount-card-pdf__infos">
                <div className="discount-card-pdf__infos__contact">
                    <p className="discount-card-pdf__infos__contact__header">
                        {t('pages.discountCard.pdf.contactAndLegal.questions')}
                    </p>
                    <div>
                        <PhoneIcon />
                        <p>{t('pages.discountCard.pdf.contactAndLegal.number')}</p>
                    </div>
                    <div>
                        <GlobeIcon />
                        <p>{t('pages.discountCard.pdf.contactAndLegal.url')}</p>
                    </div>
                </div>
                <p
                    className="discount-card-pdf__infos__legal-text"
                    dangerouslySetInnerHTML={{
                        __html: t('pages.discountCard.pdf.contactAndLegal.legalText')
                    }}
                ></p>
            </div>
        </div>
    );
};

export default PDF;
