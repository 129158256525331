import { Form, Formik, FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';
import Text from 'ui-kit/text/text';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { ModalComponentContent, ModalComponentFooter } from 'components/modal/modal.component';

import { discountCardSendSmsEmailRoutine } from 'state/discount-card/discount-card.routines';
import { discountCardGeneratedDiscountCardSelector } from 'state/discount-card/discount-card.selector';
import { openModalComponent } from 'state/modal/modal.reducer';

import { validatePhoneNumber } from 'schema/validation/phone-number';

import { DiscountCardResponse, SendSmsEmailCardPayload } from 'types/discount-card';

import './modal-contents.styles.scss';
import SuccessContent from './success.component';

const SEND_SMS_SCHEMA = yup.object().shape({
    fullName: yup.string().required(),
    phoneNumber: yup
        .string()
        .test('Phone Number validation', (value: string | undefined, context: yup.TestContext<Record<string, any>>) =>
            validatePhoneNumber(value, context, 'US')
        )
});

interface SendSmsContentProps {
    handleCloseModal: () => void;
}

const parsePhoneError = (errors: Record<string, any>, translation: TFunction<'translation'>) => {
    const phoneErrorMessages: Record<string, any> = {
        phoneNumber: translation('pages.getYourCard.sendSMS.modal.form.inputPhone.error'),
        phoneNumberInvalid: translation('pages.getYourCard.sendSMS.modal.form.inputPhone.invalid')
    };

    return errors?.phoneNumber ? phoneErrorMessages[errors.phoneNumber] : undefined;
};

const SendSmsContent = ({ handleCloseModal }: SendSmsContentProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

    const generatedCardSelector = useSelector(discountCardGeneratedDiscountCardSelector) as DiscountCardResponse;

    /**
     * @params values: any, helpers: FormikHelpers<any>
     * @todo Define the payload and response values to be used in this form submission
     */
    const handleSubmitForm = (values: any, helpers: FormikHelpers<any>) => {
        setIsSubmitting(true);

        const sendPayload: SendSmsEmailCardPayload = {
            name: values.fullName,
            communicationAddress: values.phoneNumber,
            memberId: generatedCardSelector?.cardholderID,
            bin: generatedCardSelector?.rxBIN,
            pcn: generatedCardSelector?.rxPCN,
            group: generatedCardSelector?.groupID
        };

        dispatch(
            discountCardSendSmsEmailRoutine.trigger({
                data: sendPayload,
                onSuccess: (response: any) => {
                    setIsSubmitting(false);
                    dispatch(
                        openModalComponent({
                            hasDefaultTitle: false,
                            hasDefaultFooter: false,
                            hasCustomContent: false,
                            content: <SuccessContent copyText={t('pages.getYourCard.sendSMS.modal.success.copy')} />,
                            variation: 'small',
                            isCentered: true,
                            onClose: handleCloseModal
                        })
                    );
                },
                onFailure: (error: any) => {
                    setHasSubmitError(true);
                    setIsSubmitting(false);
                }
            })
        );
    };

    return (
        <ModalComponentContent>
            {/* Custom modal content with form and custom actions */}
            <Formik
                initialValues={{
                    fullName: '',
                    phoneNumber: ''
                }}
                validationSchema={SEND_SMS_SCHEMA}
                onSubmit={handleSubmitForm}
            >
                {({ handleChange, handleSubmit, setFieldTouched, handleBlur, values, errors, touched }) => {
                    return (
                        <Form id="send-sms-form" autoComplete="off">
                            <div className="modal-send-sms">
                                {hasSubmitError && (
                                    <div className="modal-send-sms-warning">
                                        <ToastBox icon="danger" variant="danger">
                                            <p>{t('pages.getYourCard.sendSMS.modal.form.submitError')}</p>
                                        </ToastBox>
                                    </div>
                                )}

                                <div className="modal-send-sms-title">
                                    <p>{t('pages.getYourCard.sendSMS.modal.form.copy')}</p>
                                </div>

                                <Text
                                    name="fullName"
                                    label={t('pages.getYourCard.sendSMS.modal.form.inputName.label')}
                                    value={values.fullName}
                                    defaultValue={values?.fullName}
                                    touched={touched.fullName}
                                    onChange={handleChange}
                                    onBlur={(e) => {
                                        setFieldTouched('fullName');
                                        handleBlur(e);
                                    }}
                                    errors={
                                        errors?.fullName
                                            ? t('pages.getYourCard.sendSMS.modal.form.inputName.error')
                                            : undefined
                                    }
                                />

                                <PhoneNumberText
                                    name="phoneNumber"
                                    label={t('pages.getYourCard.sendSMS.modal.form.inputPhone.label')}
                                    onChange={handleChange}
                                    onBlur={(e) => {
                                        setFieldTouched('phoneNumber');
                                        handleBlur(e);
                                    }}
                                    errors={parsePhoneError(errors, t)}
                                    touched={touched.phoneNumber}
                                    countryCode={t('countryCode')}
                                    value={values?.phoneNumber}
                                />
                            </div>
                            <ModalComponentFooter
                                continueButtonLabel={t('pages.getYourCard.sendSMS.modal.form.sendCta')}
                                cancelButtonLabel={t('pages.getYourCard.sendSMS.modal.form.cancelCta')}
                                onCancel={handleCloseModal}
                                onContinue={handleSubmit}
                                isCTABusy={isSubmitting}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </ModalComponentContent>
    );
};

export default SendSmsContent;
