import { CardIconProps } from './card.props';

const CardIcon = ({ className, style }: CardIconProps) => {
    return (
        <div className={className} style={style}>
            <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M40.6648 79.2008C62.3144 79.2008 79.8648 61.6504 79.8648 40.0008C79.8648 18.3512 62.3144 0.800781 40.6648 0.800781C19.0152 0.800781 1.46484 18.3512 1.46484 40.0008C1.46484 61.6504 19.0152 79.2008 40.6648 79.2008Z"
                    fill="#F2F9FC"
                />
                <path
                    d="M40.1339 69.9344C50.9601 69.9344 59.7365 69.0449 59.7365 67.9477C59.7365 66.8504 50.9601 65.9609 40.1339 65.9609C29.3077 65.9609 20.5312 66.8504 20.5312 67.9477C20.5312 69.0449 29.3077 69.9344 40.1339 69.9344Z"
                    fill="#D6E7FF"
                />
                <path
                    d="M68.2833 60.0524C68.0673 61.1509 67.4243 62.1189 66.4953 62.7436C65.5662 63.3684 64.4273 63.5989 63.3284 63.3843L23.6893 55.8658C22.5874 55.6627 21.6113 55.0301 20.9758 54.1074C20.3403 53.1845 20.0974 52.0472 20.3004 50.9452L25.0044 26.1688C25.219 25.0689 25.8616 24.0993 26.791 23.4732C27.7204 22.8471 28.8604 22.6156 29.9604 22.83L69.5984 30.3496C70.7003 30.5527 71.6764 31.1852 72.3119 32.108C72.9474 33.0308 73.1903 34.1682 72.9873 35.2701L68.2833 60.0524Z"
                    fill="#F3F8FF"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M23.9258 31.8496L22.5938 38.8715L70.5774 47.9736L71.9094 40.9518L23.9258 31.8496Z"
                    fill="#5CC4ED"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M54.1401 16.1797H14.211C11.8897 16.1797 10.0078 18.0615 10.0078 20.3829V45.6012C10.0078 47.9226 11.8897 49.8044 14.211 49.8044H54.1401C56.4615 49.8044 58.3434 47.9226 58.3434 45.6012V20.3829C58.3434 18.0615 56.4615 16.1797 54.1401 16.1797Z"
                    fill="white"
                />
                <path
                    d="M54.1401 16.1797H14.211C11.8897 16.1797 10.0078 18.0615 10.0078 20.3829V45.6012C10.0078 47.9226 11.8897 49.8044 14.211 49.8044H54.1401C56.4615 49.8044 58.3434 47.9226 58.3434 45.6012V20.3829C58.3434 18.0615 56.4615 16.1797 54.1401 16.1797Z"
                    fill="white"
                />
                <path
                    d="M55.5 32.994C55.5 29.9867 53.0621 27.5488 50.0548 27.5488C47.0475 27.5488 44.6096 29.9867 44.6096 32.994C44.6096 36.0013 47.0475 38.4392 50.0548 38.4392C53.0621 38.4392 55.5 36.0013 55.5 32.994Z"
                    fill="#5CC4ED"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M51.9628 30.5176C52.4355 30.5176 52.8551 30.7335 53.119 31.0674C53.3396 31.3465 53.6068 31.5893 53.8955 31.8049C53.9931 31.8777 53.9931 32.0185 53.8955 32.0913L53.2212 32.5724C52.9941 32.8193 52.8482 33.1224 52.7879 33.4456C52.5449 34.7449 51.3531 35.7316 49.9186 35.7316C48.9645 35.7316 48.1178 35.2945 47.5861 34.6201L47.1022 35.0625C46.9452 35.1884 46.7107 35.1689 46.579 35.0194C46.4473 34.8696 46.4677 34.6465 46.6247 34.5207L48.2662 33.1866L49.8627 31.8892L50.7102 31.2003L51.21 30.7974C51.2377 30.775 51.2665 30.754 51.296 30.734L51.2969 30.7334C51.301 30.7306 51.3052 30.7277 51.3095 30.725C51.486 30.6087 51.6911 30.5356 51.9062 30.5198C51.9262 30.5184 51.9452 30.5176 51.9628 30.5176Z"
                    fill="#222A3F"
                    stroke="#222A3F"
                />
                <path
                    d="M49.2396 31.1992C48.871 30.7886 48.2342 30.7449 47.8109 31.1013L47.802 31.1089C47.3895 31.4646 47.3493 32.0808 47.7122 32.4851L48.2792 33.1728L49.8597 31.9005L49.2396 31.1992Z"
                    fill="#222A3F"
                    stroke="#222A3F"
                    strokeLinejoin="round"
                />
                <path d="M35.7109 42.4316H53.5793" stroke="#020064" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M54.1401 16.1797H14.211C11.8897 16.1797 10.0078 18.0615 10.0078 20.3829V45.6012C10.0078 47.9226 11.8897 49.8044 14.211 49.8044H54.1401C56.4615 49.8044 58.3434 47.9226 58.3434 45.6012V20.3829C58.3434 18.0615 56.4615 16.1797 54.1401 16.1797Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M58.3855 24.2029L58.3434 20.3809C58.3434 19.2662 57.9005 18.1971 57.1123 17.4088C56.324 16.6205 55.2549 16.1777 54.1401 16.1777H14.211C13.6591 16.1777 13.1125 16.2864 12.6025 16.4977C12.0925 16.7089 11.6293 17.0185 11.2389 17.4088C10.8486 17.7991 10.539 18.2625 10.3278 18.7725C10.1165 19.2824 10.0078 19.829 10.0078 20.3809L10.05 24.2029H58.3855Z"
                    fill="#5CC4ED"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.009 41.8732L10.0511 45.6952C10.0511 46.81 10.494 47.8791 11.2822 48.6674C12.0706 49.4556 13.1396 49.8984 14.2544 49.8984L54.1835 49.8984C54.7354 49.8984 55.2821 49.7898 55.792 49.5785C56.302 49.3673 56.7653 49.0576 57.1557 48.6674C57.5459 48.2771 57.8555 47.8137 58.0667 47.3037C58.278 46.7938 58.3867 46.2472 58.3867 45.6952L58.3446 41.8732L10.009 41.8732Z"
                    fill="#222A3F"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M56.8695 19.4768C56.7546 18.9793 56.5021 18.5241 56.141 18.1631C55.7799 17.8021 55.3246 17.5499 54.827 17.4351L50.7286 16.4887C50.6534 16.4715 50.5862 16.4294 50.5382 16.3691C50.4901 16.3088 50.4639 16.234 50.4639 16.1569C50.4639 16.0798 50.4901 16.005 50.5382 15.9447C50.5862 15.8844 50.6534 15.8423 50.7286 15.8251L54.8225 14.8795C55.3201 14.7648 55.7754 14.5125 56.1365 14.1516C56.4977 13.7906 56.7501 13.3354 56.8651 12.8379L57.8151 8.74122C57.8323 8.66605 57.8744 8.59892 57.9347 8.55083C57.995 8.50275 58.0698 8.47656 58.1469 8.47656C58.224 8.47656 58.2988 8.50275 58.3591 8.55083C58.4194 8.59892 58.4615 8.66605 58.4787 8.74122L59.4243 12.8379C59.539 13.3355 59.7914 13.7908 60.1526 14.1518C60.5137 14.5128 60.9691 14.765 61.4668 14.8795L65.5635 15.8251C65.6386 15.8423 65.7058 15.8844 65.7539 15.9447C65.8019 16.005 65.8281 16.0798 65.8281 16.1569C65.8281 16.234 65.8019 16.3088 65.7539 16.3691C65.7058 16.4294 65.6386 16.4715 65.5635 16.4887L61.4668 17.4342C60.9692 17.5493 60.5139 17.8017 60.1528 18.1628C59.7917 18.5239 59.5393 18.9792 59.4243 19.4768L58.4787 23.5761C58.4615 23.6513 58.4194 23.7184 58.3591 23.7665C58.2988 23.8146 58.224 23.8408 58.1469 23.8408C58.0698 23.8408 57.995 23.8146 57.9347 23.7665C57.8744 23.7184 57.8323 23.6513 57.8151 23.5761L56.8695 19.4768Z"
                    fill="#FAD541"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
export default CardIcon;
