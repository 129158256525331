import scriptSaveIcon from 'assets/images/scriptsave.png';
import { useTranslation } from 'react-i18next';

import BadgeIcon from 'ui-kit/icons/discount-card/badge/badge';
import ScriptSaveIcon from 'ui-kit/icons/discount-card/scriptSave/scriptSave';
import Logo from 'ui-kit/logo/logo';

import './card.styles.scss';

export interface DiscountCardProps {
    memberId: string;
    bin: string;
    pcn: string;
    group: string;
    useSVG?: boolean;
}

const Card = ({ memberId, bin, pcn, group, useSVG = true }: DiscountCardProps) => {
    const { t } = useTranslation();
    return (
        <div className="discount-card-svg">
            <div className="discount-card-svg__header">
                <div className="discount-card-svg__header__content">
                    <Logo variant="primary" />
                    <p>{t('pages.discountCard.card.header')}</p>
                    {useSVG ? <ScriptSaveIcon /> : <img src={scriptSaveIcon} alt="ScriptSave" />}
                </div>
            </div>
            <div className="discount-card-svg__information">
                <div className="discount-card-svg__information__container">
                    <div className="discount-card-svg__information__container__information">
                        <div className="discount-card-svg__information__container__information__member">
                            <p>{t('pages.discountCard.card.information.memberId')}</p>
                            <span>{memberId}</span>
                        </div>
                        <div className="discount-card-svg__information__container__information__other-codes">
                            <div>
                                <p>{t('pages.discountCard.card.information.bin')}</p>
                                <p>{bin}</p>
                            </div>
                            <div>
                                <p>{t('pages.discountCard.card.information.pcn')}</p>
                                <p>{pcn}</p>
                            </div>
                            <div>
                                <p>{t('pages.discountCard.card.information.group')}</p>
                                <p>{group}</p>
                            </div>
                        </div>
                    </div>
                    <BadgeIcon />
                </div>
            </div>
            <div className="discount-card-svg__questions">
                <div className="discount-card-svg__questions__contact">
                    <p>{t('pages.discountCard.card.questions.gotQuestions')}</p>
                    <div className="discount-card-svg__questions__contact__numbers">
                        <div>
                            <p>{t('pages.discountCard.card.questions.numbers.patientCare.label')}</p>
                            <p>{t('pages.discountCard.card.questions.numbers.patientCare.number')}</p>
                        </div>
                        <div>
                            <p>{t('pages.discountCard.card.questions.numbers.pharmacists.label')}</p>
                            <p>{t('pages.discountCard.card.questions.numbers.pharmacists.number')}</p>
                        </div>
                    </div>
                </div>
                <div className="discount-card-svg__questions__note">{t('pages.discountCard.card.questions.note')}</div>
            </div>
            <div className="discount-card-svg__footer">
                <div className="discount-card-svg__footer__link-and-note">
                    <a href="/">{t('pages.discountCard.card.footer.webSite')}</a>
                    <p>{t('pages.discountCard.card.footer.note1')}</p>
                </div>
                <p className="discount-card-svg__footer__note">{t('pages.discountCard.card.footer.note2')}</p>
            </div>
        </div>
    );
};

export default Card;
