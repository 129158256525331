import { MagnifyingGlassIconProps } from './magnifying-glass.props';

const MagnifyingGlassIcon = ({ className, style }: MagnifyingGlassIconProps) => {
    return (
        <div className={className} style={style}>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.19141 40.0007C1.19141 69.8751 33.5314 88.5466 59.4034 73.6093C71.4107 66.677 78.8074 53.8654 78.8074 40.0007C78.8074 10.1263 46.4674 -8.5453 20.5954 6.39198C8.58813 13.3244 1.19141 26.1359 1.19141 40.0007Z"
                    fill="#F2F9FC"
                />
                <path
                    d="M46.7012 69.5358C46.7012 70.4662 41.1711 71.2211 34.3467 71.2211C27.5222 71.2211 21.9922 70.4652 21.9922 69.5358C21.9922 68.6064 27.5223 67.8516 34.3468 67.8516C41.1712 67.8516 46.7012 68.6054 46.7012 69.5358Z"
                    fill="#D6E7FF"
                />
                <path
                    d="M47.4453 48.1786L52.0703 43.5527L57.4646 48.947L52.8396 53.573L47.4453 48.1786Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M68.2545 58.1935C70.6276 60.5663 69.5422 64.6182 66.3008 65.4871C64.7964 65.8903 63.1912 65.4603 62.0898 64.3591L52.8429 55.1112C52.417 54.6859 52.417 53.9957 52.8429 53.5705L57.4629 48.9465C57.8886 48.5204 58.579 48.5204 59.0046 48.9465L68.2545 58.1935Z"
                    fill="#5CC4ED"
                />
                <path
                    d="M68.2545 58.1935C70.6276 60.5663 69.5422 64.6182 66.3008 65.4871C64.7964 65.8903 63.1912 65.4603 62.0898 64.3591L52.8429 55.1112C52.417 54.6859 52.417 53.9957 52.8429 53.5705L57.4629 48.9465C57.8886 48.5204 58.579 48.5204 59.0046 48.9465L68.2545 58.1935Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.4609 30.4532C11.4506 48.0709 30.5158 59.0932 45.7784 50.2933C52.8701 46.2044 57.2379 38.6392 57.2331 30.4532C57.2435 12.8355 38.1782 1.81323 22.9156 10.6131C15.8239 14.702 11.4561 22.2672 11.4609 30.4532Z"
                    fill="#5CC4ED"
                />
                <path
                    d="M30.2156 47.9539C43.6877 51.1341 55.5504 38.5378 51.5684 25.2806C49.7205 19.1279 44.7304 14.4285 38.478 12.9526C25.0059 9.77239 13.1433 22.3687 17.1252 35.6259C18.9732 41.7786 23.9633 46.478 30.2156 47.9539Z"
                    fill="white"
                />
                <path
                    d="M30.2156 47.9539C43.6877 51.1341 55.5504 38.5378 51.5684 25.2806C49.7205 19.1279 44.7304 14.4285 38.478 12.9526C25.0059 9.77239 13.1433 22.3687 17.1252 35.6259C18.9732 41.7786 23.9633 46.478 30.2156 47.9539Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M36.7793 37.4881C38.4646 36.7452 38.7137 34.4566 37.2277 33.3685C37.088 33.2662 36.9377 33.1794 36.7793 33.1096V37.4881ZM32.2562 23.7598C30.5713 24.5016 30.3213 26.7893 31.8063 27.8775C31.9464 27.9803 32.0972 28.0674 32.2562 28.1373V23.7598Z"
                    fill="#F2F9FC"
                />
                <path
                    d="M34.5169 44.785C33.2687 44.7839 32.257 43.7726 32.2554 42.5245V42.2111C30.4106 42.0463 28.7217 41.1088 27.6062 39.6304C26.5605 38.2393 27.4129 36.238 29.1404 36.0281C29.232 36.0169 29.3242 36.0113 29.4166 36.0115C30.1286 36.0069 30.8002 36.3426 31.2241 36.9148C31.4811 37.2594 31.843 37.5113 32.2554 37.6327V32.7817C26.9922 32.0488 24.4963 25.8932 27.7625 21.7016C28.866 20.2857 30.4774 19.3553 32.2554 19.1078V18.7197C32.2586 16.9787 34.1453 15.8941 35.6513 16.7675C36.3477 17.1712 36.7769 17.9147 36.7784 18.7197V19.1485C38.8654 19.5614 40.6745 20.8508 41.7459 22.6887C42.3722 23.7695 42.0033 25.1533 40.9222 25.7789C39.841 26.4072 38.455 26.037 37.8312 24.9532C37.5793 24.5217 37.2178 24.1645 36.7833 23.918V28.4614C42.0486 29.1836 44.5578 35.3349 41.2996 39.5338C40.193 40.9599 38.5718 41.896 36.7833 42.1413V42.5293C36.779 43.7775 35.7651 44.7867 34.5169 44.785ZM36.7784 37.4882C38.4637 36.7453 38.7128 34.4566 37.2269 33.3685C37.0871 33.2663 36.9368 33.1795 36.7784 33.1097V37.4882ZM32.2554 23.7598C30.5704 24.5017 30.3205 26.7894 31.8055 27.8776C31.9456 27.9803 32.0964 28.0673 32.2554 28.1374V23.7598Z"
                    fill="#97D3B7"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.4609 30.4532C11.4506 48.0709 30.5158 59.0932 45.7784 50.2933C52.8701 46.2044 57.2379 38.6392 57.2331 30.4532C57.2435 12.8355 38.1782 1.81323 22.9156 10.6131C15.8239 14.702 11.4561 22.2672 11.4609 30.4532Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
export default MagnifyingGlassIcon;
