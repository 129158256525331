import React from 'react';

import { ScriptSaveIconProps } from './payment.props';

const PaymentIcon = ({ className, style }: ScriptSaveIconProps) => {
    return (
        <div className={className} style={style}>
            <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.76953 35C7.76953 42.4261 10.7195 49.548 15.9705 54.799C21.2216 60.05 28.3435 63 35.7695 63C43.1956 63 50.3175 60.05 55.5685 54.799C60.8195 49.548 63.7695 42.4261 63.7695 35C63.7695 27.5739 60.8195 20.452 55.5685 15.201C50.3175 9.94999 43.1956 7 35.7695 7C28.3435 7 21.2216 9.94999 15.9705 15.201C10.7195 20.452 7.76953 27.5739 7.76953 35Z"
                    fill="#5CC4ED"
                />
                <path
                    d="M37.137 28.1441C36.9668 28.2417 36.818 28.3726 36.6994 28.529C36.5809 28.6853 36.495 28.8639 36.4469 29.0542C36.3988 29.2444 36.3895 29.4424 36.4196 29.6363C36.4496 29.8302 36.5184 30.0161 36.6218 30.1829C36.7252 30.3497 36.8611 30.494 37.0214 30.6071C37.1817 30.7203 37.3631 30.8 37.5549 30.8416C37.7467 30.8832 37.9449 30.8857 38.1376 30.8491C38.3304 30.8124 38.5138 30.7374 38.677 30.6284L44.6557 26.9184C44.3399 27.1292 44.1185 27.4547 44.0384 27.8259C43.9583 28.1971 44.0257 28.5849 44.2264 28.9073C44.4271 29.2296 44.7454 29.4613 45.1138 29.5532C45.4822 29.6452 45.872 29.5902 46.2006 29.3999L46.8215 29.0142C46.5091 29.2261 46.2907 29.5507 46.2121 29.9199C46.1334 30.2892 46.2005 30.6745 46.3994 30.9954C46.5983 31.3163 46.9135 31.5479 47.2792 31.6418C47.6448 31.7357 48.0326 31.6846 48.3615 31.4992L48.9824 31.1135C48.6527 31.3188 48.4181 31.6467 48.3301 32.025C48.2421 32.4034 48.3081 32.8011 48.5134 33.1309C48.7187 33.4606 49.0466 33.6952 49.425 33.7832C49.8033 33.8711 50.2011 33.8052 50.5308 33.5999L54.2576 31.285C57.458 29.2977 57.2473 27.7066 59.0764 26.5705L61.5432 25.0382L57.1066 17.8919C56.8192 18.0702 56.4943 18.1795 56.1575 18.2111C55.8206 18.2426 55.4811 18.1957 55.1655 18.0739L54.0147 17.6602L37.137 28.1441Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M37.137 28.1441C36.9668 28.2417 36.818 28.3726 36.6994 28.529C36.5809 28.6853 36.495 28.8639 36.4469 29.0542C36.3988 29.2444 36.3895 29.4424 36.4196 29.6363C36.4496 29.8302 36.5184 30.0161 36.6218 30.1829C36.7252 30.3497 36.8611 30.494 37.0214 30.6071C37.1817 30.7203 37.3631 30.8 37.5549 30.8416C37.7467 30.8832 37.9449 30.8857 38.1376 30.8491C38.3304 30.8124 38.5138 30.7374 38.677 30.6284L44.6557 26.9184C44.3399 27.1292 44.1185 27.4547 44.0384 27.8259C43.9583 28.1971 44.0257 28.5849 44.2264 28.9073C44.4271 29.2296 44.7454 29.4613 45.1138 29.5532C45.4822 29.6452 45.872 29.5902 46.2006 29.3999L46.8215 29.0142C46.5091 29.2261 46.2907 29.5507 46.2121 29.9199C46.1334 30.2892 46.2005 30.6745 46.3994 30.9954C46.5983 31.3163 46.9135 31.5479 47.2792 31.6418C47.6448 31.7357 48.0326 31.6846 48.3615 31.4992L48.9824 31.1135C48.6527 31.3188 48.4181 31.6467 48.3301 32.025C48.2421 32.4034 48.3081 32.8011 48.5134 33.1309C48.7187 33.4606 49.0466 33.6952 49.425 33.7832C49.8033 33.8711 50.2011 33.8052 50.5308 33.5999L54.2576 31.285C57.458 29.2977 57.2473 27.7066 59.0764 26.5705L61.5432 25.0382L57.1066 17.8919C56.8192 18.0702 56.4943 18.1795 56.1575 18.2111C55.8206 18.2426 55.4811 18.1957 55.1655 18.0739L54.0147 17.6602L37.137 28.1441Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M50.1678 25.2148C50.3722 25.5444 50.4375 25.9416 50.3491 26.3193C50.2608 26.6969 50.0261 27.024 49.6967 27.2287L45.6591 29.7368C45.33 29.9284 44.939 29.9841 44.5695 29.892C44.1999 29.7998 43.8809 29.5672 43.6803 29.2434C43.4796 28.9197 43.4132 28.5304 43.4952 28.1585C43.5771 27.7865 43.801 27.4612 44.1191 27.2518L48.1567 24.7444C48.4859 24.5406 48.8825 24.4756 49.2595 24.5638C49.6365 24.652 49.9631 24.8861 50.1678 25.2148Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M52.3302 27.3144C52.5347 27.644 52.5999 28.0412 52.5116 28.4189C52.4232 28.7965 52.1886 29.1236 51.8591 29.3283L47.8215 31.8364C47.6584 31.9454 47.4749 32.0205 47.2822 32.0571C47.0894 32.0937 46.8912 32.0912 46.6994 32.0496C46.5077 32.0081 46.3262 31.9283 46.1659 31.8152C46.0056 31.702 45.8697 31.5577 45.7663 31.391C45.6629 31.2242 45.5942 31.0383 45.5641 30.8444C45.5341 30.6505 45.5434 30.4525 45.5914 30.2622C45.6395 30.072 45.7254 29.8934 45.844 29.737C45.9625 29.5806 46.1113 29.4497 46.2815 29.3521L50.3191 26.844C50.6483 26.6402 51.0449 26.5753 51.4219 26.6634C51.7989 26.7516 52.1256 26.9857 52.3302 27.3144Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M52.941 30.3797C53.0424 30.5428 53.1106 30.7243 53.1418 30.9138C53.173 31.1033 53.1666 31.2971 53.1229 31.4841C53.0792 31.6711 52.9992 31.8477 52.8872 32.0037C52.7753 32.1598 52.6337 32.2923 52.4706 32.3936L49.9856 33.9336C49.8224 34.0426 49.639 34.1177 49.4462 34.1543C49.2535 34.1909 49.0553 34.1884 48.8635 34.1468C48.6717 34.1053 48.4903 34.0255 48.33 33.9124C48.1697 33.7992 48.0338 33.6549 47.9304 33.4882C47.827 33.3214 47.7582 33.1355 47.7282 32.9416C47.6981 32.7477 47.7074 32.5497 47.7555 32.3594C47.8036 32.1692 47.8895 31.9906 48.008 31.8342C48.1266 31.6778 48.2754 31.5469 48.4456 31.4493L50.9299 29.9058C51.2595 29.7021 51.6564 29.6376 52.0335 29.7265C52.4107 29.8153 52.737 30.0503 52.941 30.3797Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M39.73 28.709C40.0761 28.709 40.408 28.8465 40.6527 29.0912C40.8974 29.3358 41.0348 29.6677 41.0348 30.0138C41.0348 30.3598 40.8974 30.6917 40.6527 30.9364C40.408 31.1811 40.0761 31.3186 39.73 31.3186H33.4503C33.7964 31.3186 34.1283 31.4561 34.373 31.7008C34.6177 31.9454 34.7551 32.2773 34.7551 32.6234C34.7551 32.9694 34.6177 33.3013 34.373 33.546C34.1283 33.7907 33.7964 33.9282 33.4503 33.9282H32.7979C33.144 33.9282 33.4759 34.0657 33.7206 34.3104C33.9653 34.555 34.1027 34.8869 34.1027 35.233C34.1027 35.579 33.9653 35.9109 33.7206 36.1556C33.4759 36.4003 33.144 36.5378 32.7979 36.5378H32.1455C32.4916 36.5378 32.8235 36.6753 33.0682 36.92C33.3129 37.1646 33.4503 37.4965 33.4503 37.8426C33.4503 38.1886 33.3129 38.5205 33.0682 38.7652C32.8235 39.0099 32.4916 39.1474 32.1455 39.1474H28.2311C24.8711 39.1474 24.281 37.8426 22.3595 37.8426H19.7695V30.34C20.0713 30.3398 20.369 30.2697 20.6391 30.1351C20.9092 30.0006 21.1444 29.8052 21.3263 29.5644L22.0039 28.709H39.73Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M39.73 28.709C40.0761 28.709 40.408 28.8465 40.6527 29.0912C40.8974 29.3358 41.0348 29.6677 41.0348 30.0138C41.0348 30.3598 40.8974 30.6917 40.6527 30.9364C40.408 31.1811 40.0761 31.3186 39.73 31.3186H33.4503C33.7964 31.3186 34.1283 31.4561 34.373 31.7008C34.6177 31.9454 34.7551 32.2773 34.7551 32.6234C34.7551 32.9694 34.6177 33.3013 34.373 33.546C34.1283 33.7907 33.7964 33.9282 33.4503 33.9282H32.7979C33.144 33.9282 33.4759 34.0657 33.7206 34.3104C33.9653 34.555 34.1027 34.8869 34.1027 35.233C34.1027 35.579 33.9653 35.9109 33.7206 36.1556C33.4759 36.4003 33.144 36.5378 32.7979 36.5378H32.1455C32.4916 36.5378 32.8235 36.6753 33.0682 36.92C33.3129 37.1646 33.4503 37.4965 33.4503 37.8426C33.4503 38.1886 33.3129 38.5205 33.0682 38.7652C32.8235 39.0099 32.4916 39.1474 32.1455 39.1474H28.2311C24.8711 39.1474 24.281 37.8426 22.3595 37.8426H19.7695V30.34C20.0713 30.3398 20.369 30.2697 20.6391 30.1351C20.9092 30.0006 21.1444 29.8052 21.3263 29.5644L22.0039 28.709H39.73Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M28.4766 32.6193C28.4766 32.9653 28.614 33.2972 28.8587 33.5419C29.1034 33.7866 29.4353 33.9241 29.7814 33.9241H34.0213C34.3673 33.9241 34.6992 33.7866 34.9439 33.5419C35.1886 33.2972 35.3261 32.9653 35.3261 32.6193C35.3261 32.2732 35.1886 31.9413 34.9439 31.6966C34.6992 31.4519 34.3673 31.3145 34.0213 31.3145H29.7814C29.61 31.3144 29.4403 31.348 29.2819 31.4136C29.1236 31.4791 28.9797 31.5752 28.8585 31.6964C28.7373 31.8176 28.6412 31.9615 28.5757 32.1198C28.5102 32.2782 28.4765 32.4479 28.4766 32.6193Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.8242 35.2317C27.8239 35.4033 27.8573 35.5732 27.9227 35.7319C27.9881 35.8905 28.0842 36.0347 28.2054 36.1561C28.3266 36.2776 28.4706 36.374 28.629 36.4397C28.7875 36.5054 28.9574 36.5393 29.129 36.5393H33.3696C33.7157 36.5393 34.0476 36.4018 34.2923 36.1571C34.537 35.9124 34.6744 35.5805 34.6744 35.2345C34.6744 34.8884 34.537 34.5566 34.2923 34.3119C34.0476 34.0672 33.7157 33.9297 33.3696 33.9297H29.129C28.7835 33.9297 28.452 34.0668 28.2074 34.3109C27.9628 34.555 27.825 34.8861 27.8242 35.2317Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M28.8008 37.8419C28.8007 38.0133 28.8344 38.183 28.8999 38.3413C28.9655 38.4997 29.0616 38.6436 29.1827 38.7647C29.3039 38.8859 29.4478 38.982 29.6061 39.0476C29.7645 39.1131 29.9342 39.1468 30.1056 39.1467H32.7152C33.0612 39.1467 33.3931 39.0092 33.6378 38.7645C33.8825 38.5198 34.02 38.188 34.02 37.8419C34.02 37.4959 33.8825 37.164 33.6378 36.9193C33.3931 36.6746 33.0612 36.5371 32.7152 36.5371H30.1056C29.7595 36.5371 29.4276 36.6746 29.1829 36.9193C28.9383 37.164 28.8008 37.4959 28.8008 37.8419Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.809 38.263C17.8091 38.3747 17.7871 38.4854 17.7444 38.5886C17.7017 38.6919 17.6391 38.7857 17.5601 38.8648C17.4811 38.9438 17.3874 39.0066 17.2841 39.0493C17.1809 39.0921 17.0702 39.1142 16.9585 39.1142H13.2422V29.002H16.9585C17.1841 29.002 17.4004 29.0916 17.5599 29.2511C17.7194 29.4106 17.809 29.6269 17.809 29.8525V38.263Z"
                    fill="#5CC4ED"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.809 38.263C17.8091 38.3747 17.7871 38.4854 17.7444 38.5886C17.7017 38.6919 17.6391 38.7857 17.5601 38.8648C17.4811 38.9438 17.3874 39.0066 17.2841 39.0493C17.1809 39.0921 17.0702 39.1142 16.9585 39.1142H13.2422V29.002H16.9585C17.1841 29.002 17.4004 29.0916 17.5599 29.2511C17.7194 29.4106 17.809 29.6269 17.809 29.8525V38.263Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M63.6563 24.2783C63.7223 24.3846 63.8087 24.4769 63.9105 24.5499C64.0123 24.6229 64.1274 24.6751 64.2493 24.7036C64.3713 24.7321 64.4977 24.7362 64.6212 24.7159C64.7448 24.6955 64.8631 24.651 64.9695 24.5849L68.5073 22.3876L62.5272 12.7598L58.9894 14.9571C58.883 15.0231 58.7907 15.1095 58.7177 15.2113C58.6447 15.3131 58.5925 15.4282 58.564 15.5502C58.5356 15.6721 58.5314 15.7985 58.5518 15.922C58.5721 16.0456 58.6166 16.1639 58.6828 16.2703L63.6563 24.2783Z"
                    fill="#222A3F"
                />
                <path
                    d="M62.5272 12.7598L58.9894 14.9571C58.883 15.0231 58.7907 15.1095 58.7177 15.2113C58.6447 15.3131 58.5925 15.4282 58.564 15.5502C58.5356 15.6721 58.5314 15.7985 58.5518 15.922C58.5721 16.0456 58.6166 16.1639 58.6828 16.2703L63.6563 24.2783C63.7223 24.3846 63.8087 24.4769 63.9105 24.5499C64.0123 24.6229 64.1274 24.6751 64.2493 24.7036C64.3713 24.7321 64.4977 24.7362 64.6212 24.7159C64.7448 24.6955 64.8631 24.651 64.9695 24.5849L68.5073 22.3876"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.49219 55.8551C1.49219 56.1844 2.32401 56.5003 3.80467 56.7332C5.28532 56.9661 7.29352 57.0969 9.38749 57.0969C11.4815 57.0969 13.4897 56.9661 14.9703 56.7332C16.451 56.5003 17.2828 56.1844 17.2828 55.8551C17.2828 55.5257 16.451 55.2099 14.9703 54.977C13.4897 54.7441 11.4815 54.6133 9.38749 54.6133C7.29352 54.6133 5.28532 54.7441 3.80467 54.977C2.32401 55.2099 1.49219 55.5257 1.49219 55.8551Z"
                    fill="#D6E7FF"
                />
                <path
                    d="M13.5689 53.8594C13.7375 53.9478 13.8717 54.0902 13.9498 54.2638C14.028 54.4373 14.0458 54.6321 14.0002 54.817C13.9547 55.0018 13.8485 55.1661 13.6986 55.2835C13.5487 55.4009 13.3638 55.4646 13.1734 55.4645H3.38672C3.38616 54.6565 3.5665 53.8586 3.91452 53.1293L12.1479 35.8652L12.815 39.1993L8.11452 51.5403C8.07028 51.6564 8.05186 51.7806 8.06052 51.9045C8.06919 52.0284 8.10474 52.1488 8.1647 52.2576C8.22467 52.3663 8.3076 52.4607 8.40773 52.5341C8.50786 52.6075 8.62279 52.6582 8.74452 52.6827L12.1969 53.3743C12.675 53.4701 13.1369 53.6334 13.5689 53.8594Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.934 52.1175C17.9436 52.173 17.9421 52.2298 17.9296 52.2846C17.9172 52.3395 17.8941 52.3914 17.8616 52.4373C17.829 52.4832 17.7878 52.5222 17.7402 52.5522C17.6926 52.5821 17.6395 52.6023 17.584 52.6118L16.8462 52.7406C16.7532 52.7564 16.6576 52.7414 16.5739 52.6978C15.5621 52.166 14.6859 51.4089 14.0128 50.485C13.3398 49.561 12.8878 48.4949 12.6917 47.3687L8.84873 25.2795C8.65287 24.1534 8.71813 22.9973 9.0395 21.9003C9.36086 20.8033 9.92975 19.7947 10.7023 18.9523C10.7661 18.8829 10.851 18.8364 10.9438 18.8199L11.6823 18.6918C11.7378 18.6822 11.7946 18.6836 11.8495 18.696C11.9044 18.7084 11.9563 18.7316 12.0022 18.7641C12.0481 18.7966 12.0872 18.8379 12.1171 18.8856C12.147 18.9332 12.1672 18.9863 12.1765 19.0418L17.934 52.1175Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M47.0935 17.4314L29.2389 19.0291C28.2009 19.122 27.4362 20.0557 27.5309 21.1147L28.5603 32.6186C28.6551 33.6775 29.5734 34.4607 30.6114 34.3678L48.466 32.7701C49.504 32.6773 50.2687 31.7435 50.174 30.6846L49.1446 19.1806C49.0498 18.1217 48.1315 17.3385 47.0935 17.4314Z"
                    fill="white"
                    stroke="#222A3F"
                />
                <path
                    d="M48.1939 24.2616C48.0954 23.1614 47.1238 22.3493 46.0236 22.4478L45.1272 22.528C44.027 22.6264 43.2149 23.5981 43.3134 24.6983L43.3936 25.5947C43.492 26.6949 44.4637 27.5069 45.5639 27.4085L46.4603 27.3283C47.5605 27.2298 48.3725 26.2582 48.2741 25.158L48.1939 24.2616Z"
                    fill="#5CC4ED"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M46.4922 23.6428C46.7126 23.6231 46.9175 23.7088 47.0548 23.8572C47.1696 23.9814 47.3045 24.0862 47.4484 24.1771C47.497 24.2078 47.503 24.275 47.4606 24.3139L47.1668 24.5718C47.0714 24.6992 47.0164 24.8501 47.002 25.007C46.9443 25.6377 46.4307 26.1587 45.7619 26.2186C45.317 26.2584 44.9036 26.0849 44.6268 25.785L44.4201 26.0165C44.3523 26.0831 44.2421 26.0836 44.1743 26.0177C44.1065 25.9517 44.1065 25.8443 44.1743 25.7776L44.8827 25.0719L45.5716 24.3856L45.9373 24.0212L46.1531 23.8079C46.1651 23.7961 46.1776 23.7848 46.1905 23.774L46.1964 23.7692C46.2738 23.7063 46.3663 23.6628 46.4659 23.6463C46.4752 23.6448 46.484 23.6436 46.4922 23.6428Z"
                    fill="#222A3F"
                />
                <path
                    d="M45.2515 24.0826C45.0622 23.9019 44.7634 23.9076 44.5812 24.0955L44.5774 24.0995C44.4003 24.2866 44.4079 24.5826 44.5944 24.7606L44.8881 25.0654L45.5707 24.3917L45.2515 24.0826Z"
                    fill="#222A3F"
                />
                <path
                    d="M49.285 20.7493L49.1176 19.0908C49.0743 18.6065 48.8348 18.1598 48.452 17.8489C48.0692 17.538 47.5744 17.3884 47.0763 17.433L29.2372 19.0292C28.9907 19.0513 28.7507 19.1203 28.5311 19.2325C28.3114 19.3446 28.1165 19.4977 27.9572 19.6828C27.798 19.868 27.6777 20.0816 27.6032 20.3116C27.5286 20.5415 27.5013 20.7833 27.5228 21.0231L27.6901 22.6817L49.285 20.7493Z"
                    fill="#5CC4ED"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M28.4182 31.0495L28.5855 32.7081C28.6289 33.1923 28.8683 33.639 29.2511 33.9499C29.6339 34.2608 30.1287 34.4104 30.6268 34.3659L48.4659 32.7696C48.7125 32.7475 48.9525 32.6785 49.1721 32.5663C49.3917 32.4542 49.5867 32.3011 49.7459 32.116C49.9051 31.9309 50.0254 31.7172 50.0999 31.4872C50.1745 31.2573 50.2018 31.0155 50.1804 30.7757L50.013 29.1172L28.4182 31.0495Z"
                    fill="#222A3F"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.5145 28.7092C30.8606 28.7092 31.1925 28.5717 31.4372 28.327C31.6819 28.0823 31.8193 27.7505 31.8193 27.4044C31.8193 27.0584 31.6819 26.7265 31.4372 26.4818C31.1925 26.2371 30.8606 26.0996 30.5145 26.0996H26.0786C25.4293 26.0998 24.7886 26.249 24.206 26.536C23.6235 26.8229 23.1146 27.2397 22.7186 27.7544L21.3263 29.5646C21.1444 29.8054 20.9092 30.0008 20.6391 30.1354C20.369 30.2699 20.0713 30.34 19.7695 30.3402V32.9498H23.3395C23.8965 32.9499 24.448 32.8403 24.9626 32.6272C25.4771 32.4141 25.9447 32.1018 26.3385 31.708C26.7324 31.3142 27.0448 30.8467 27.258 30.3322C27.4711 29.8176 27.5808 29.2662 27.5808 28.7092H30.5166"
                    fill="white"
                />
                <path
                    d="M22.3594 32.9496H23.3394C23.8963 32.9497 24.4478 32.8401 24.9624 32.627C25.477 32.4139 25.9445 32.1016 26.3384 31.7078C26.7322 31.314 27.0447 30.8465 27.2578 30.332C27.471 29.8174 27.5807 29.2659 27.5807 28.709H30.5165"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.5145 28.7092C30.8606 28.7092 31.1925 28.5717 31.4372 28.327C31.6819 28.0823 31.8193 27.7505 31.8193 27.4044C31.8193 27.0584 31.6819 26.7265 31.4372 26.4818C31.1925 26.2371 30.8606 26.0996 30.5145 26.0996H26.0786C25.4293 26.0998 24.7886 26.249 24.206 26.536C23.6235 26.8229 23.1146 27.2397 22.7186 27.7544L21.3263 29.5646C21.1444 29.8054 20.9092 30.0008 20.6391 30.1354C20.369 30.2699 20.0713 30.34 19.7695 30.3402"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.9114 22.6941C45.7482 22.8031 45.5648 22.8782 45.372 22.9148C45.1792 22.9515 44.9811 22.9489 44.7893 22.9074C44.5975 22.8658 44.4161 22.7861 44.2557 22.6729C44.0954 22.5597 43.9595 22.4155 43.8561 22.2487C43.7528 22.0819 43.684 21.896 43.654 21.7021C43.6239 21.5082 43.6332 21.3102 43.6813 21.12C43.7294 20.9297 43.8152 20.7511 43.9338 20.5947C44.0524 20.4384 44.2012 20.3075 44.3714 20.2098L48.5952 17.5862C49.2136 17.2022 49.912 16.9654 50.6364 16.894C51.3609 16.8226 52.092 16.9186 52.7735 17.1746L55.1696 18.0741C55.4852 18.196 55.8247 18.2429 56.1615 18.2113C56.4983 18.1798 56.8232 18.0705 57.1107 17.8921L58.6507 20.3764L56.1832 21.9101L55.2515 22.4883C54.7213 22.8177 54.1315 23.0394 53.5156 23.1408C52.8998 23.2422 52.2699 23.2213 51.6622 23.0793C51.0544 22.9372 50.4806 22.6769 49.9734 22.313C49.4663 21.9492 49.0358 21.489 48.7065 20.9588L45.9114 22.6948"
                    fill="white"
                />
                <path
                    d="M56.182 21.9103L55.2503 22.4885C54.7201 22.8179 54.1302 23.0396 53.5144 23.141C52.8985 23.2424 52.2687 23.2214 51.6609 23.0794C51.0532 22.9374 50.4793 22.677 49.9722 22.3132C49.465 21.9494 49.0345 21.4892 48.7053 20.959L45.9102 22.695"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.9114 22.6941C45.7482 22.8031 45.5648 22.8782 45.372 22.9148C45.1792 22.9515 44.9811 22.9489 44.7893 22.9074C44.5975 22.8658 44.4161 22.7861 44.2557 22.6729C44.0954 22.5597 43.9595 22.4155 43.8561 22.2487C43.7528 22.0819 43.684 21.896 43.654 21.7021C43.6239 21.5082 43.6332 21.3102 43.6813 21.12C43.7294 20.9297 43.8152 20.7511 43.9338 20.5947C44.0524 20.4384 44.2012 20.3075 44.3714 20.2098L48.5952 17.5862C49.2136 17.2022 49.912 16.9654 50.6364 16.894C51.3609 16.8226 52.092 16.9186 52.7735 17.1746L55.1696 18.0741C55.4852 18.1959 55.8247 18.2429 56.1615 18.2113C56.4983 18.1798 56.8232 18.0705 57.1107 17.8921"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M63.7694 24.468L62.7194 25.1218C62.613 25.1879 62.4947 25.2325 62.3712 25.2528C62.2476 25.2732 62.1212 25.269 61.9993 25.2406C61.8773 25.2121 61.7622 25.1599 61.6604 25.0869C61.5587 25.0139 61.4723 24.9216 61.4062 24.8152L57.2062 18.0497C57.1401 17.9434 57.0956 17.825 57.0752 17.7015C57.0548 17.5779 57.059 17.4516 57.0875 17.3296C57.1159 17.2077 57.1682 17.0925 57.2411 16.9908C57.3141 16.889 57.4064 16.8026 57.5128 16.7365L58.5628 16.082L63.7694 24.468Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.8086 38.4617H18.9153C19.027 38.4617 19.1377 38.4397 19.2409 38.3969C19.3442 38.3541 19.4379 38.2914 19.5169 38.2123C19.5959 38.1333 19.6586 38.0395 19.7013 37.9362C19.744 37.8329 19.7659 37.7222 19.7658 37.6105V30.5048C19.7658 30.3931 19.7438 30.2825 19.7011 30.1793C19.6583 30.0761 19.5957 29.9824 19.5167 29.9034C19.4377 29.8244 19.344 29.7618 19.2408 29.719C19.1376 29.6763 19.027 29.6543 18.9153 29.6543H17.8086V38.4617Z"
                    fill="white"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
export default PaymentIcon;
