import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import Text from 'ui-kit/text/text';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { ModalComponentContent, ModalComponentFooter } from 'components/modal/modal.component';

import { discountCardSendSmsEmailRoutine } from 'state/discount-card/discount-card.routines';
import { discountCardGeneratedDiscountCardSelector } from 'state/discount-card/discount-card.selector';
import { openModalComponent } from 'state/modal/modal.reducer';

import { DiscountCardResponse, SendSmsEmailCardPayload } from 'types/discount-card';

import './modal-contents.styles.scss';
import SuccessContent from './success.component';

const SEND_EMAIL_SCHEMA = yup.object().shape({
    name: yup.string().required('required'),
    email: yup.string().email('invalid').required('required')
});

interface SendDiscountCardEmailProps {
    handleCloseModal: () => void;
}

const SendDiscountCardEmail = ({ handleCloseModal }: SendDiscountCardEmailProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

    const discountCard = useSelector(discountCardGeneratedDiscountCardSelector) as DiscountCardResponse;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSubmitForm = useCallback(
        (values: any) => {
            setIsSubmitting(true);
            const formatPayload: SendSmsEmailCardPayload = {
                communicationAddress: values.email,
                name: values.name,
                memberId: discountCard?.cardholderID,
                bin: discountCard?.rxBIN,
                pcn: discountCard?.rxPCN,
                group: discountCard?.groupID
            };

            dispatch(
                discountCardSendSmsEmailRoutine.trigger({
                    data: formatPayload,
                    onSuccess: (response: any) => {
                        setIsSubmitting(false);
                        dispatch(
                            openModalComponent({
                                hasDefaultTitle: false,
                                hasDefaultFooter: false,
                                hasCustomContent: false,
                                content: (
                                    <SuccessContent copyText={t('pages.getYourCard.sendEmail.modal.success.copy')} />
                                ),
                                variation: 'small',
                                isCentered: true,
                                onClose: handleCloseModal
                            })
                        );
                    },
                    onFailure: (error: any) => {
                        setHasSubmitError(true);
                        setIsSubmitting(false);
                    }
                })
            );
        },
        [discountCard]
    );

    return (
        <ModalComponentContent>
            <Formik
                initialValues={{
                    name: '',
                    email: ''
                }}
                validationSchema={SEND_EMAIL_SCHEMA}
                onSubmit={handleSubmitForm}
            >
                {({ handleChange, handleSubmit, values, errors, touched }) => {
                    return (
                        <Form id="send-email-form" autoComplete="off">
                            <div className="modal-send-email">
                                {hasSubmitError && (
                                    <div className="modal-send-email__warning-container">
                                        <ToastBox icon="danger" variant="danger">
                                            <p>{t('pages.getYourCard.sendEmail.modal.error')}</p>
                                        </ToastBox>
                                    </div>
                                )}

                                <small className="modal-send-email__form-detail">
                                    {t('pages.getYourCard.sendEmail.modal.form.formDetail')}
                                </small>

                                <div className="modal-send-email__input-container">
                                    <Text
                                        name="name"
                                        className="modal-send-email__input-container__name-input"
                                        label={t('pages.getYourCard.sendEmail.modal.form.inputNameLabel')}
                                        value={values.name}
                                        touched={touched.name}
                                        onChange={handleChange}
                                        errors={
                                            errors.name
                                                ? t('pages.getYourCard.sendEmail.modal.form.inputNameError')
                                                : undefined
                                        }
                                    />
                                </div>
                                <div className="modal-send-email__input-container">
                                    <Text
                                        name="email"
                                        className="modal-send-email__input-container__email-input"
                                        label={t('pages.getYourCard.sendEmail.modal.form.inputEmailLabel')}
                                        value={values.email}
                                        touched={touched.email}
                                        onChange={handleChange}
                                        errors={
                                            errors.email === 'invalid'
                                                ? t('pages.getYourCard.sendEmail.modal.form.inputEmailInvalidError')
                                                : errors.email === 'required'
                                                ? t(`pages.getYourCard.sendEmail.modal.form.inputEmailRequiredError`)
                                                : undefined
                                        }
                                    />
                                </div>
                            </div>

                            <ModalComponentFooter
                                continueButtonLabel={t('pages.getYourCard.sendEmail.modal.actions.sendBtn')}
                                cancelButtonLabel={t('pages.getYourCard.sendEmail.modal.actions.cancelBtn')}
                                onCancel={handleCloseModal}
                                onContinue={handleSubmit}
                                isCTABusy={isSubmitting}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </ModalComponentContent>
    );
};

export default SendDiscountCardEmail;
