import { useTranslation } from 'react-i18next';

import SmileyIcon from 'ui-kit/illustrations/smiley-icon/smiley-icon';

import './modal-contents.styles.scss';

interface SuccessContentProps {
    copyText: string;
}

const SuccessContent = ({ copyText }: SuccessContentProps) => {
    const { t } = useTranslation();

    return (
        <div className="modal-success-content">
            <SmileyIcon />
            <h3>{t('components.discountCardModals.titles.success')}</h3>
            {copyText && <p>{copyText}</p>}
        </div>
    );
};

export default SuccessContent;
