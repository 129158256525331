import { PhoneIconProps } from './phone.props';

const PhoneIcon = ({ className, style }: PhoneIconProps) => {
    return (
        <div className={className} style={style}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_983_71585)">
                    <path
                        d="M6.20159 14.1451C5.57129 14.5523 4.8202 14.7298 4.07462 14.6477C3.32904 14.5656 2.63442 14.2288 2.10759 13.6941L1.64701 13.243C1.44509 13.0361 1.33203 12.7584 1.33203 12.4691C1.33203 12.1798 1.44509 11.902 1.64701 11.6952L3.6019 9.75778C3.80671 9.5562 4.08237 9.44325 4.36953 9.44325C4.65668 9.44325 4.93235 9.5562 5.13715 9.75778C5.34368 9.96002 5.62106 10.0733 5.9099 10.0733C6.19874 10.0733 6.4761 9.96002 6.68263 9.75778L9.75314 6.68256C9.85557 6.58146 9.93691 6.46098 9.99243 6.32812C10.0479 6.19526 10.0765 6.05266 10.0765 5.90863C10.0765 5.7646 10.0479 5.62201 9.99243 5.48914C9.93691 5.35628 9.85557 5.2358 9.75314 5.1347C9.55186 4.92958 9.43909 4.65349 9.43909 4.3659C9.43909 4.0783 9.55186 3.80221 9.75314 3.59709L11.6978 1.64945C11.9043 1.44722 12.1817 1.33398 12.4705 1.33398C12.7594 1.33398 13.0367 1.44722 13.2433 1.64945L13.6937 2.11074C14.2275 2.63838 14.5637 3.33406 14.6458 4.08078C14.7278 4.82752 14.5506 5.57976 14.1439 6.21103C12.0253 9.33826 9.32947 12.0312 6.20159 14.1451Z"
                        stroke="#1A497F"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_983_71585">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
export default PhoneIcon;
