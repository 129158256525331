import { GlobeIconProps } from './globe.props';

const GlobeIcon = ({ className, style }: GlobeIconProps) => {
    return (
        <div className={className} style={style}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_983_71579)">
                    <path
                        d="M7.9987 14.6673C11.6806 14.6673 14.6654 11.6826 14.6654 8.00065C14.6654 4.31875 11.6806 1.33398 7.9987 1.33398C4.3168 1.33398 1.33203 4.31875 1.33203 8.00065C1.33203 11.6826 4.3168 14.6673 7.9987 14.6673Z"
                        stroke="#1A497F"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M1.66797 8L14.3346 8"
                        stroke="#1A497F"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M11.3346 8C11.1709 10.1941 10.0035 12.2954 8.0013 14C5.99906 12.2954 4.8317 10.1941 4.66797 8C4.8317 5.80586 5.99906 3.7046 8.0013 2C10.0035 3.7046 11.1709 5.80586 11.3346 8Z"
                        stroke="#1A497F"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_983_71579">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
export default GlobeIcon;
