import { PrinterIconProps } from './printer.props';

const PrinterIcon = ({ className, style }: PrinterIconProps) => {
    return (
        <div className={className} style={style}>
            <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.52344 40.0007C1.52344 69.8751 33.8634 88.5466 59.7354 73.6093C71.7427 66.677 79.1394 53.8654 79.1394 40.0007C79.1394 10.1263 46.7995 -8.5453 20.9274 6.39198C8.92016 13.3244 1.52344 26.1359 1.52344 40.0007Z"
                    fill="#F2F9FC"
                />
                <path
                    d="M13.207 59.4058C13.1957 60.972 35.7919 61.9517 53.8801 61.1694C62.284 60.806 67.46 60.1335 67.4548 59.4058C67.4661 57.8397 44.8699 56.8599 26.7817 57.6422C18.3778 58.0057 13.2018 58.6782 13.207 59.4058Z"
                    fill="#F2F9FC"
                />
                <path d="M11.8516 33.2891H68.8111V57.7003H11.8516V33.2891Z" fill="white" />
                <path
                    d="M57.4185 57.6995H23.2422V53.9962C23.2427 53.3456 23.7695 52.8181 24.4201 52.8164H56.2426C56.8938 52.817 57.4214 53.345 57.4214 53.9962L57.4185 57.6995Z"
                    fill="#1A497F"
                />
                <path
                    d="M59.0485 33.2873H21.6172V27.9571C21.6177 27.3058 22.1456 26.7779 22.797 26.7773H57.8688C58.5203 26.7773 59.0485 27.3055 59.0485 27.9571L59.0485 33.2873Z"
                    fill="#5CC4ED"
                />
                <path
                    d="M55.0598 21.8965H25.6007C24.2982 21.8966 23.2422 22.9525 23.2422 24.2551V26.7776H57.4184V24.2551C57.419 22.9522 56.3627 21.8959 55.0598 21.8965Z"
                    fill="#5CC4ED"
                />
                <path d="M44.3984 38.1699H55.7896V43.052H44.3984V38.1699Z" fill="#FAD541" />
                <path
                    d="M11.8516 33.2891H68.8111V57.7003H11.8516V33.2891Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M57.4185 57.6995H23.2422V53.9962C23.2427 53.3456 23.7695 52.8181 24.4201 52.8164H56.2426C56.8938 52.817 57.4214 53.345 57.4214 53.9962L57.4185 57.6995Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M59.0485 33.2873H21.6172V27.9571C21.6177 27.3058 22.1456 26.7779 22.797 26.7773H57.8688C58.5203 26.7773 59.0485 27.3055 59.0485 27.9571L59.0485 33.2873Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M55.0598 21.8965H25.6007C24.2982 21.8966 23.2422 22.9525 23.2422 24.2551V26.7776H57.4184V24.2551C57.419 22.9522 56.3627 21.8959 55.0598 21.8965Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M44.3984 38.1699H55.7896V43.052H44.3984V38.1699Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M54.1653 33.288H26.5V13.7588C26.5 12.8601 27.2283 12.1314 28.1271 12.1309H47.6553L54.1653 18.6409V33.288Z"
                    fill="white"
                />
                <path
                    d="M47.6523 12.1309V17.2855C47.6523 18.0357 48.2604 18.6438 49.0106 18.6438H54.1643L47.6523 12.1309Z"
                    fill="#5CC4ED"
                />
                <path
                    d="M54.1653 33.288H26.5V13.7588C26.5 12.8601 27.2283 12.1314 28.1271 12.1309H47.6553L54.1653 18.6409V33.288Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M47.6523 12.1309V17.2855C47.6523 18.0357 48.2604 18.6438 49.0106 18.6438H54.1643"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M18.3633 39.7988H24.8723" stroke="#222A3F" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M54.1653 52.8164V67.4664C54.1653 68.365 53.4368 69.0934 52.5383 69.0935H28.1271C27.2285 69.0935 26.5 68.3651 26.5 67.4665V52.8165L54.1653 52.8164Z"
                    fill="white"
                />
                <path
                    d="M54.1653 52.8164V67.4664C54.1653 68.365 53.4368 69.0934 52.5383 69.0935H28.1271C27.2285 69.0935 26.5 68.3651 26.5 67.4665V52.8165L54.1653 52.8164Z"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M31.3789 64.209H46.0261" stroke="#222A3F" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M31.3789 60.9531H49.281" stroke="#222A3F" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M31.3789 57.6992H49.281" stroke="#222A3F" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M63.9289 40.6109C63.9289 42.4901 61.8947 43.6645 60.2674 42.725C58.64 41.7854 58.64 39.4366 60.2674 38.497C60.6388 38.2826 61.0601 38.1698 61.4889 38.1699C62.8367 38.1705 63.9289 39.2632 63.9289 40.6109Z"
                    fill="#97D3B7"
                    stroke="#222A3F"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
export default PrinterIcon;
