import { graphql, navigate } from 'gatsby';
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import ChatButtonIcon from 'ui-kit/icons/chat-button-icon';
import DownloadButtonIcon from 'ui-kit/icons/download-button-icon';
import EmailButtonIcon from 'ui-kit/icons/email-button-icon';
import PageSection from 'ui-kit/page-section/page-section';
import ShadowBox from 'ui-kit/shadow-box';
import Spinner from 'ui-kit/spinner/spinner';

import DiscountCardInfo from 'display-components/birdi-discount/discount-card-info';
import HowItWorksSection from 'display-components/birdi-discount/how-it-works-section';
import SendDiscountCardEmail from 'display-components/birdi-discount/modal-contents/send-email.component';
import SendSmsContent from 'display-components/birdi-discount/modal-contents/send-sms.component';

import BirdiDiscountSearchBanner from 'components/birdi-discount-search-banner';
import Banner from 'components/discount-card/banner';
import Card from 'components/discount-card/card';
import LegalText from 'components/discount-card/legal-text/legal-text.component';
import PDF from 'components/discount-card/pdf';
import Pharmacies from 'components/discount-card/pharmacies/pharmacies.component';
import { FeatureCardProps } from 'components/feature-card/feature-card.props';
import FrequentlyAnsweredQuestions from 'components/frequently-answered-questions/frequently-answered-questions.component';
import PageLayout from 'components/layouts/page/page.layout';
import PrescriptionErrorOrNoResults from 'components/prescription-error-or-no-results/prescription-error-or-no-results.component';

import { setDiscountCardVoucher } from 'state/discount-card/discount-card.reducers';
import { generateDiscountCardRoutine } from 'state/discount-card/discount-card.routines';
import { discountCardGeneratedDiscountCardSelector } from 'state/discount-card/discount-card.selector';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

import { DiscountCardResponse } from 'types/discount-card';

import { discountCardLegalPaths } from '../index';
import './index.style.scss';

const GetYourCardPage = ({ data }: { data: GatsbyTypes.GetYourCardDataQuery }) => {
    const {
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        appleWallet,
        featureCardDiscountData,
        featureCardSearchData,
        featureCardCompletedData,
        showCardBirdi,
        medicineInformation
    } = data;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const discountCard = useSelector(discountCardGeneratedDiscountCardSelector);
    const [isDiscountCardGenerated, setDiscountCardGenerated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const [isPrinting, setIsPrinting] = useState(false);
    const targetRef = useRef();

    useEffect(() => {
        dispatch(
            generateDiscountCardRoutine.trigger({
                onSuccess: () => {
                    setIsLoading(false);
                    setDiscountCardGenerated(true);
                },
                onFailure: () => {
                    setIsLoading(false);
                }
            })
        );
    }, []);

    const cardToRender = (isDiscountCardGenerated: boolean, discountCard?: DiscountCardResponse) => {
        return isDiscountCardGenerated && discountCard ? (
            <>
                <Card
                    bin={discountCard.rxBIN}
                    group={discountCard.groupID}
                    memberId={discountCard.cardholderID}
                    pcn={discountCard.rxPCN}
                />
            </>
        ) : (
            <>
                <PrescriptionErrorOrNoResults
                    title={t('pages.discountCard.somethingWentWrong.title')}
                    subTitle={t('pages.discountCard.somethingWentWrong.subTitle')}
                    variant={'error'}
                    buttonLabel={t('pages.discountCard.somethingWentWrong.tryAgain')}
                    onNavigate={() => navigate('/discount-card')}
                />
            </>
        );
    };

    const generateDiscountCardPDF = async () => {
        try {
            await setIsPrinting(true);

            const isMobile = window.innerWidth < 700;

            await generatePDF(targetRef, {
                method: isMobile ? 'save' : 'open',
                resolution: isMobile ? Resolution.MEDIUM : Resolution.HIGH,
                page: {
                    margin: { top: Margin.MEDIUM, left: Margin.LARGE, right: Margin.LARGE, bottom: 0 },
                    format: 'letter',
                    orientation: 'portrait'
                },
                canvas: {
                    mimeType: 'image/jpeg',
                    qualityRatio: isMobile ? 0.7 : 1
                },
                overrides: {
                    pdf: {
                        compress: true
                    },
                    canvas: {
                        useCORS: true
                    }
                }
            });
        } catch (error) {
            console.error('PDF generation failed:', error);
        } finally {
            await setIsPrinting(false);
        }
    };

    const sendEmailHandler = () => {
        dispatch(
            openModalComponent({
                title: t('pages.getYourCard.sendEmail.modal.form.title'),
                hasDefaultFooter: false,
                hasCustomContent: true,
                content: <SendDiscountCardEmail handleCloseModal={() => dispatch(closeModalComponent())} />,
                variation: 'small',
                isCentered: true,
                onClose: () => dispatch(closeModalComponent())
            })
        );
    };

    const handleOpenModalText = () => {
        dispatch(
            openModalComponent({
                title: t('pages.getYourCard.sendSMS.modal.form.title'),
                hasDefaultFooter: false,
                hasCustomContent: true,
                content: <SendSmsContent handleCloseModal={() => dispatch(closeModalComponent())} />,
                variation: 'small',
                isCentered: true,
                onClose: () => dispatch(closeModalComponent())
            })
        );
    };

    useEffect(() => {
        // mock generated card
        dispatch(
            setDiscountCardVoucher({
                memberId: (discountCard?.cardholderID as string) || 'BRD30242801',
                bin: (discountCard?.rxBIN as string) || '006053',
                group: discountCard?.groupID || '4530',
                pcn: discountCard?.rxPCN || 'MSC'
            })
        );
    }, []);

    // @MARK: featureCardsItems
    const featureCardsItems: FeatureCardProps[] = [
        {
            title: t('pages.discountCard.howItWorks.featureCards.discount.title'),
            description: t('pages.discountCard.howItWorks.featureCards.discount.description'),
            image: featureCardDiscountData as ImageDataLike
        },
        {
            title: t('pages.discountCard.howItWorks.featureCards.search.title'),
            description: t('pages.discountCard.howItWorks.featureCards.search.description'),
            image: featureCardSearchData as ImageDataLike
        },
        {
            title: t('pages.discountCard.howItWorks.featureCards.completed.title'),
            description: t('pages.discountCard.howItWorks.featureCards.completed.description'),
            image: featureCardCompletedData as ImageDataLike
        }
    ];

    // @MARK: Render
    return (
        <div className="birdi-get-your-card-page">
            <PageLayout
                metaData={{ nodeTitle: t('pages.getYourCard.pageTitle') }}
                isShortFooter={true}
                hideSpecialtyAddress={true}
                legalPaths={discountCardLegalPaths}
            >
                <PageSection className="mb-4 mb-lg-5">
                    {/*
                        @MARK: Page Hero
                     */}

                    <ShadowBox className="birdi-get-your-card-page__card">
                        <div className="birdi-get-your-card-page__card__top">
                            <div className="birdi-get-your-card-page__card__top__left">
                                <h1 className="birdi-get-your-card-page__card__title">
                                    {t('pages.getYourCard.pageTitle')}
                                </h1>
                                <p
                                    className="birdi-get-your-card-page__card__description"
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(t('pages.getYourCard.pageDescription'), {
                                            allowedTags: ['strong']
                                        })
                                    }}
                                />
                            </div>

                            {/* @TODO: Extract to component */}

                            {isLoading ? (
                                <ShadowBox className="birdi-cart-member-id">
                                    <Spinner isVisible={isLoading} t={t} isDotsSpinner={true} />
                                </ShadowBox>
                            ) : (
                                cardToRender(isDiscountCardGenerated, discountCard)
                            )}
                        </div>
                        <div className="birdi-get-your-card-page__card__bottom">
                            <p className="birdi-get-your-card-page__card__get-card">{t('pages.getYourCard.getCard')}</p>
                            <div className="birdi-get-your-card-page__button-wrapper">
                                <Button
                                    className="birdi-get-your-card-page__button"
                                    label={t('pages.getYourCard.buttons.text')}
                                    type={'button'}
                                    customIcon={
                                        <ChatButtonIcon className={!isDiscountCardGenerated ? 'disabled' : ''} />
                                    }
                                    onClick={handleOpenModalText}
                                    disabled={!isDiscountCardGenerated}
                                />
                                <Button
                                    className="birdi-get-your-card-page__button"
                                    label={t('pages.getYourCard.buttons.email')}
                                    type={'button'}
                                    customIcon={
                                        <EmailButtonIcon className={!isDiscountCardGenerated ? 'disabled' : ''} />
                                    }
                                    onClick={sendEmailHandler}
                                    disabled={!isDiscountCardGenerated}
                                />
                                <Button
                                    className="birdi-get-your-card-page__button"
                                    label={t('pages.getYourCard.buttons.print')}
                                    type={'button'}
                                    customIcon={
                                        <DownloadButtonIcon className={!isDiscountCardGenerated ? 'disabled' : ''} />
                                    }
                                    disabled={!isDiscountCardGenerated || isPrinting}
                                    onClick={generateDiscountCardPDF}
                                    async
                                />
                            </div>
                            {/*  TODO: This action is planned for implementation as detailed in https://mincainc.atlassian.net/browse/DRX-3657
                            <div className="birdi-get-your-card-page__separator-inline">
                                <span>{t('pages.getYourCard.divider')}</span>
                            </div>
                            <GatsbyImage
                                image={getImage(appleWallet as ImageDataLike) as IGatsbyImageData}
                                alt={t('pages.getYourCard.buttons.appleWallet')}
                                objectFit="contain"
                                className="birdi-get-your-card-page__apple-wallet"
                            /> */}
                        </div>
                    </ShadowBox>
                </PageSection>

                {/*
                    @MARK: How the Birdi Discount Card works.
                */}
                <div className="birdi-get-your-card-page__how-it-works">
                    <PageSection className="mb-4 mb-lg-5">
                        <HowItWorksSection
                            featureCards={featureCardsItems}
                            title={t('pages.discountCard.howItWorks.title')}
                            subtitle={t('pages.discountCard.howItWorks.subtitle')}
                        />
                    </PageSection>
                </div>

                {/*
                    @MARK: Get your prescriptions delivered to you
                */}
                {isBannerVisible && (
                    <PageSection className="birdi-get-your-card-page__get-your-prescription">
                        <Banner
                            variation="GET_PRESCRIPTION"
                            title={t('pages.getYourCard.getYourPrescriptionBanner.title')}
                            description={t('pages.getYourCard.getYourPrescriptionBanner.description')}
                            label={t('pages.getYourCard.getYourPrescriptionBanner.label')}
                            handleClick={() => navigate('/get-started')}
                            secondaryLabel={t('pages.getYourCard.getYourPrescriptionBanner.secondaryLabel')}
                            secondaryHandleClick={() => {
                                setIsBannerVisible(false);
                            }}
                            isLoading={false}
                        />
                    </PageSection>
                )}

                {/*
                    @MARK: We got you covered
                */}
                <PageSection className="birdi-get-your-card-page__we-got-you-covered">
                    <DiscountCardInfo
                        eyebrow={t('pages.getYourCard.gotCoveredHero.eyebrow')}
                        title={t('pages.getYourCard.gotCoveredHero.title')}
                        description={[
                            t('pages.getYourCard.gotCoveredHero.descriptions.0'),
                            t('pages.getYourCard.gotCoveredHero.descriptions.1'),
                            t('pages.getYourCard.gotCoveredHero.descriptions.2')
                        ]}
                        image={showCardBirdi as ImageDataLike}
                    />
                </PageSection>

                {/*
                    @MARK:Find the lowest price at the right pharmacy!
                */}
                <PageSection className="birdi-get-your-card-page__discount_search">
                    <BirdiDiscountSearchBanner
                        title={t('pages.getYourCard.discountSearchBanner.title')}
                        subtitle={t('pages.getYourCard.discountSearchBanner.subtitle')}
                        image={medicineInformation as ImageDataLike}
                    />
                </PageSection>

                {/*
                    @MARK: Accepted at more than 60,000
                */}
                <PageSection className="birdi-get-your-card-page__pharmacies">
                    <Pharmacies />
                </PageSection>

                {/*
                    @MARK: FAQ
                */}
                <PageSection className="birdi-get-your-card-page__faq">
                    <FrequentlyAnsweredQuestions />
                </PageSection>

                {/*
                    @MARK: DISCOUNT ONLY Disclaimer
                */}
                <div className="birdi-discount-card-page__legal-text-container">
                    <PageSection>
                        <LegalText />
                    </PageSection>
                </div>

                {discountCard && isPrinting && (
                    <PDF
                        bin={discountCard?.rxBIN}
                        group={discountCard?.groupID}
                        memberId={discountCard?.cardholderID}
                        pcn={discountCard?.rxPCN}
                        targetRef={targetRef}
                    />
                )}
            </PageLayout>
        </div>
    );
};

export default GetYourCardPage;

// @MARK: Query
export const query = graphql`
    query GetYourCardData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        appleWallet: file(relativePath: { eq: "assets/images/add-to-apple-wallet.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }

        featureCardDiscountData: file(relativePath: { eq: "assets/images/feature-card-discount.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }

        featureCardSearchData: file(relativePath: { eq: "assets/images/feature-card-search.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }

        featureCardCompletedData: file(relativePath: { eq: "assets/images/feature-card-completed.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }
        showCardBirdi: file(relativePath: { eq: "assets/images/show-card.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }
        medicineInformation: file(relativePath: { eq: "assets/images/medicine-information.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: PNG)
            }
        }
    }
`;
