const SmileyIcon = () => {
    return (
        <svg width={160} height={161} viewBox="0 0 160 161" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.38281 80.4994C2.38281 140.248 67.0628 177.591 118.807 147.717C142.821 133.852 157.615 108.229 157.615 80.4994C157.615 20.7506 92.9349 -16.5925 41.1908 13.282C17.1763 27.1468 2.38281 52.7699 2.38281 80.4994Z"
                fill="#F3F8FF"
            />
            <path
                d="M48.3594 131.309C48.3426 135.043 73.5977 137.38 93.8186 135.515C103.217 134.649 109.005 133.045 108.997 131.309C109.014 127.575 83.7588 125.238 63.5377 127.103C54.1397 127.969 48.3516 129.573 48.3594 131.309Z"
                fill="#D6E7FF"
            />
            <path d="M26.0586 40.5957H132.004V116.271H26.0586V40.5957Z" fill="white" />
            <path d="M26.0586 51.7832H132.004V61.5687H26.0586V51.7832Z" fill="#5CC4ED" />
            <path
                d="M26.0586 51.7832H132.004V61.5687H26.0586V51.7832Z"
                stroke="#222A3F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M26.0586 40.5957H132.004V116.271H26.0586V40.5957Z"
                stroke="#222A3F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M106.929 85.3503C106.929 86.9433 104.775 88.2357 102.119 88.2357C99.4625 88.2357 97.3086 86.9336 97.3086 85.3503C97.3086 83.7669 99.4624 82.4648 102.119 82.4648C104.775 82.4648 106.929 83.7476 106.929 85.3503Z"
                fill="#5CC4ED"
            />
            <path
                d="M51.1328 85.3503C51.1328 86.9433 53.2866 88.2357 55.9431 88.2357C58.5996 88.2357 60.7532 86.9433 60.7532 85.3503C60.7532 83.7572 58.5995 82.4648 55.9431 82.4648C53.2867 82.4648 51.1328 83.7476 51.1328 85.3503Z"
                fill="#5CC4ED"
            />
            <path
                d="M62.3359 73.7982C62.3359 76.5438 65.308 78.2597 67.6857 76.8869C68.7892 76.2498 69.4688 75.0726 69.4688 73.7982C69.4688 71.0529 66.4968 69.3369 64.1192 70.7098C63.0158 71.3468 62.3359 72.5241 62.3359 73.7982Z"
                fill="#222A3F"
            />
            <path
                d="M88.8242 73.7982C88.8242 76.5438 91.7963 78.2597 94.1739 76.8869C95.2773 76.2498 95.9571 75.0726 95.9571 73.7982C95.9571 71.0529 92.9851 69.3369 90.6075 70.7098C89.5039 71.3468 88.8242 72.5241 88.8242 73.7982Z"
                fill="#222A3F"
            />
            <path
                d="M79.0296 108.431C88.0886 108.624 95.9031 102.108 97.3411 93.1617C97.4092 92.6336 97.2125 92.1058 96.8153 91.7512C96.4048 91.383 95.8499 91.2204 95.3056 91.3087C84.5243 93.0356 73.5367 93.0356 62.7554 91.3087C62.2113 91.2204 61.6563 91.383 61.2458 91.7512C60.8494 92.1065 60.6529 92.6338 60.7199 93.1617C62.1571 102.108 69.971 108.624 79.0296 108.431Z"
                fill="#5CC4ED"
                stroke="#222A3F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M65.582 102.92C69.7817 100.94 74.3876 99.9733 79.0291 100.099C83.671 99.9725 88.2777 100.938 92.478 102.918C85.0183 110.266 73.0419 110.266 65.5822 102.918L65.582 102.92Z"
                fill="white"
                stroke="#222A3F"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SmileyIcon;
